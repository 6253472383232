<template>
  <div class="add-file__wrapper">
    <div class="add-file">
      <v-btn 
        color="sky"
        :outlined="outlined"
        @click="$refs['file-input'].$refs.input.click()"
      >
        <template v-if="!loader">
          <v-file-input
            ref="file-input"
            accept="image/png, image/jpeg, image/jpg, .pdf, .HEIC"
            hide-input
            multiple
            prepend-icon="mdi-plus"
            @change="addFiles"
          ></v-file-input>
          <span class="add-file__label">{{ text }}</span>
        </template>
        <div v-else class="text-center">
          <v-progress-circular
            :size="50"
            :width="2"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { DocumentService } from "@/services/documents.service.js";
export default {
  name: "AddFile",
  props: {
    outlined: {
      type: Boolean
    },
    text: {
      type: String,
      default: () => "Rajouter un document"
    }
  },
  data() {
    return {
      loader: false,
    };
  },
  methods: {
    addFiles(files) {
      this.loader = true;
      DocumentService.createDocument({
        files: files,
        quota_id: this.$route.params.quotaId,
      })
        .then(({ data }) => {
          this.$emit("addingFilesComplete");
        })
        .catch(() => {
          this.$store.commit(
            "snackbarModule/SET_ERROR_API",
            this.$t("snackbar.error.base")
          );
        })
        .finally(() => (this.loader = false));
    },
  },
};
</script>

<style src="./AddFile.scss" lang="scss" scoped></style>